import React, { useCallback, useState } from 'react';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { useSwipeable } from 'react-swipeable';
import { IEvent } from '@/modules/events';
import { EventDescription } from '@/modules/events/components';
import { SliderDots, SvgIcon } from '@/modules/shared/components';
import { useSliderInterval } from '@/modules/shared/hooks';
import { EventLink } from '@/modules/events/components/event-card/event-link.component';
import styles from './event-banner.module.scss';

interface Props {
  eventList: IEvent[] | [];
}

const EventBanner = ({ eventList = [] }: Props) => {
  const { t } = useTranslation();
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const {
    startEventInterval, stopEventInterval,
  } = useSliderInterval(eventList, setCurrentEventIndex, 4000);

  const handleEventChange = useCallback((index: number) => {
    setCurrentEventIndex(index);
    stopEventInterval();
    startEventInterval();
  }, [startEventInterval, stopEventInterval]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentEventIndex === eventList.length - 1) {
        handleEventChange(0);
      } else {
        handleEventChange(currentEventIndex + 1);
      }
    },
    onSwipedRight: () => {
      if (currentEventIndex === 0) {
        handleEventChange(eventList.length - 1);
      } else {
        handleEventChange(currentEventIndex - 1);
      }
    },
  });

  if (!eventList || eventList.length === 0) {
    return null;
  }
  const currentEvent = eventList[currentEventIndex];

  return (
    <section
      className={styles.event_banner}
      data-testid="event_banner"
      onMouseEnter={stopEventInterval}
      onMouseLeave={startEventInterval}
      {...swipeHandlers}
    >
      <EventLink event={currentEvent}>
        <div className={styles.event_banner_img}>
          <Image
            src={currentEvent?.images?.[0]?.thumbnail}
            alt="event banner"
            layout="fill"
            loading="eager"
          />
        </div>
        <div className={styles.event_banner_gradient} />
        <div className={styles.event_banner_text_wrapper}>
          <div className={styles.header}>
            <h4>{currentEvent?.title}</h4>
          </div>
          <div className={styles.description_block}>
            <span className={styles.price}>
              {currentEvent?.price === 0 && t('common:forFree')}
              {currentEvent?.price !== 0 && currentEvent?.max_price && (
                <>
                  {t('common:from')}
                  {' '}
                  {currentEvent?.price}
                  <SvgIcon name="THB-14px" />
                </>
              )}
              {currentEvent?.price !== 0 && !currentEvent?.max_price && (
                <>
                  {currentEvent?.price}
                  <SvgIcon name="THB-14px" />
                </>
              )}
            </span>
            <div className={styles.date}>
              <SvgIcon name="calendar" />
              <p>{currentEvent?.event_date}</p>
            </div>
            <EventDescription
              className={styles.event_banner_description}
              description={currentEvent?.description}
            />
          </div>
        </div>
      </EventLink>
      <div className={styles.event_banner_swiper}>
        {eventList.map((img, index) => (
          <SliderDots
            key={index}
            onChange={() => handleEventChange(index)}
            current={currentEventIndex}
            value={index}
          />
        ))}
      </div>
    </section>
  );
};

export default EventBanner;
